import React, { useContext } from "react";
import { Formik, useFormikContext } from "formik";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";
import {
    AutoComplete,
    DatePicker,
    ErrorCommon,
    NumberTextField,
    TextField,
    TextFieldCommon,
} from "../../../../formikFormInputs";
import { Label } from "../../../../formInputs";
import Typography from "@material-ui/core/Typography";
import selectField from "../../../../formikFormInputs/SelectField";
import UploadAndPreview from "../component/UploadAndPreview";
import { useActions } from "../../../../../actions";
import * as TrancheActions from "../../../../../actions/tranche";
import PaymentDocument from "../../../../clientServicing/Payments/PaymentDocument";
import moment from "moment";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { usePolicyContext } from "../../../../clientServicing/Payments/PolicyContext";
import * as PaymentActions from "../../../../../actions/payment";
import { QcPopUpContext } from "../QCPopUp/QCPopUp";
import { CheckWireContext } from "./CheckWire";
import { RequestSupportingDocs } from "../../../../clientServicing/Payments/RequestSupportingDocs";
import { enrollment } from "../../../../../reducers/enrollment";


export interface QCPopUpContentProps {
    children?:React.ReactNode
}

const fieldsName = {
    "paymentMethod": "paymentType",
    "trancheNo" : "trancheNo",
    "paymentDocumentPath" : "paymentDocumentPath",
    "amount" : "amount",
    "dateProceed" : "dateProcessed",
    "note" : "notes",
}

export default function CheckWire(props: QCPopUpContentProps) {
    const { children } = props
    const {client, enrollmentId, setOpenPopup, refreshList, setSubmitting} = useContext(CheckWireContext)
    const clientName = `${client && client.user.firstName} ${client && client.user.lastName}`
    const {policy, updatePolicy} = usePolicyContext();
    const {updateCheckWireActionItem} = useActions(PaymentActions);
    const {createManualPayment} = useActions(PaymentActions);
    const { user } = useSelector((state: RootState) => state.auth);
    const initialValues = {
        [fieldsName.paymentDocumentPath]: "",
        [fieldsName.trancheNo]:"",
        [fieldsName.amount]:"",
        [fieldsName.paymentMethod]:"",
        [fieldsName.dateProceed]:"",
        [fieldsName.note]:""
    }
    const submitFormik = async (values: any, actions: any) => {
        const submitting =(value)=>{
            setSubmitting && setSubmitting(value)
            actions.setSubmitting(value);
        }
        submitting(true)
        let policyNumber = policy.policyNumber;
        const processedBy: string = user.firstName + " " + user.lastName;
        const paymentPayload = {
            policyNumber: policyNumber,
            clientPayment: {
                date: moment().format("YYYY-MM-DD"),
                type: values.paymentType,
                details: values.notes,
                processedBy: processedBy,
                amount: [{
                    amount: numeral(values.amount).value(),
                    type: "Premium"
                }],
                files: [values.paymentDocumentPath]
            }
        };
        const policyDetails = await createManualPayment(paymentPayload);
        if(policyDetails.success != false){
            updatePolicy(policyDetails);
            await updateCheckWireActionItem({status:1, type:"approve", userId: client.userId,})
            setOpenPopup && setOpenPopup(false)
            refreshList && refreshList()
            actions.resetForm();
        }
        submitting(false)
    };

    const validationSchema = Yup.object().shape({
        [fieldsName.paymentMethod]: Yup.string().required("Required"),
        [fieldsName.amount]: Yup.string().required("Required"),
        [fieldsName.dateProceed]: Yup.string().required("Required").nullable(),
        [fieldsName.note]: Yup.string().required("Required"),
        [fieldsName.trancheNo]: Yup.string().required('Tranche number is required').nullable(),
        files: Yup.array()
    });
    return   <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={submitFormik}
    >
        {({
              handleSubmit,
              setFieldValue,
              isSubmitting,
              dirty,values, validateForm
          }:any) => {
            return (<form  id={"CheckWireForm"} onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
            }}>
                <p>Add a payment for this year. select the option below and save when completed. The amount entered will affect the balance owed</p>
                <FormFields {...props} />
                {children}
            </form>);
        }
        }
    </Formik>
};

let trancheDropDownArr: any[] = [{
    title: "",
    key: "",
    name: "",
    value: "",
}];
// @Todo need to move another common file
export const paymentMethodsAdd: object[] = [
    {
        id: "Check",
        value: "Check",
        name: "Personal Check",
    }, {
        id: "Wire",
        value: "Wire",
        name: "Wire Transfer",
    }
];
const FormFields = (props: QCPopUpContentProps) =>{
    const { values, handleChange, handleBlur, errors, touched } = useFormikContext<any>();
    const [trancheList, setTrancheList] = React.useState(trancheDropDownArr);
    const [trancheCompleteDetail, setTrancheCompleteDetail] = React.useState([]);
    const trancheActions = useActions(TrancheActions)
    const {client, enrollmentId} = useContext(CheckWireContext)
    const getTranche = async () => {
        //fetch tranche whose deadline for Application is greater than current date
        const result = await trancheActions.listUpcomingTranche(25);
        if (result  && result.length > 0) {
            const resultArr: any[] = [];
            const arr = result;
            setTrancheCompleteDetail(arr);
            arr.forEach(value => {
                const trNo = value.trancheNumber + "";
                resultArr.push({
                    title: trNo,
                    key: trNo,
                    name: trNo,
                    value: trNo,
                });
            });
            setTrancheList(resultArr);
        }
    };
    React.useEffect(() => {
            getTranche().then();
        return () => {
            setTrancheList(trancheDropDownArr);
            setTrancheCompleteDetail([]);
        };
    }, []);
    const disabled = false
    const fieldObj  = [
        {
            label: <Label label={"Payment Method"} required={true}/>,
            id: fieldsName.paymentMethod,
            name: fieldsName.paymentMethod,
            component: selectField,
            options: paymentMethodsAdd,
            disabled
        },
        {
            label: <Label label={"Tranche Assignment"} required={true}/>,
            id: fieldsName.trancheNo,
            name: fieldsName.trancheNo,
            placeholder: "Tranche List",
            component: AutoComplete,
            contentList: trancheList,
            disabled
        },
        {
            label: <Label label={"Amount"} required={true}/>,
            id: fieldsName.amount,
            name: fieldsName.amount,
            placeholder: "Amount",
            component: NumberTextField,
            disabled
        },
        {
            label: <Label label={"Date Processed"} required={true}/>,
            id: fieldsName.dateProceed,
            name: fieldsName.dateProceed,
            component: DatePicker,
            className: "fullWidthcustomFields",
            disableFuture: true,
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
            },
            disabled
        },
        {
            label: <Label label={"Check or Fedwire Reference Number"} required={true}/>,
            id: fieldsName.note,
            name: fieldsName.note,
            placeholder: "Check or Fedwire Reference Number",
            component: TextField,
            className: "fullWidthcustomFields",
            multiline: true,
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 6,
            },
            disabled
        }

    ]
    return<>
        <Grid container spacing={3}>
            {
                fieldObj.map((field, index) => {
                    return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={6} md={6} key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors}
                                                 name={field.name}
                                                 touched={touched} />
                                </Grid>
                            </React.Fragment>);
                })
            }
            <span className="hLine" />
            <Grid item xs={12} md={6} lg={6}>
                <PaymentDocument fieldName={"paymentDocumentPath"} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
               <RequestSupportingDocs directory={enrollmentId || ""} client={client} />
            </Grid>
        </Grid>
    </>

}



